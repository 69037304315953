<template>
    <div class="inscription container-xl mx-auto d-flex flex-column my-4 flex-fill body-container">
        <!-- <img src="../assets/img/fond.png" class="img-fond" alt="" /> -->

        <div v-if="isLoading" class="loader d-flex-center">
            <div class="d-flex-center flex-column bg-secondary-dark bg-opacity-75 rounded p-3 pt-4--5">
                <div class="spinner-border text-primary-dark" role="status"></div>
                <!-- <i class="fa-solid fa-glass-water text-primary-dark fa-bounce fa-2xl"></i> -->
                <!-- <i class="fa-solid fa-spinner fa-spin-pulse text-primary fa-2xl"></i> -->
                <strong class="text-primary-dark mt-3">Envoi du formulaire en cours...</strong>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-5 col-xl-6">
                <img
                    src="../assets/img/affiche.svg"
                    class="mw-100 shadow-lg border border-5 border-primary-light rounded"
                    alt=""
                />
            </div>
            <div class="col-12 col-lg-7 col-xl-6 d-flex flex-column align-items-center">
                <img src="../assets/img/logo.png" class="inscription__logo mb-4" alt="" />

                <div class="bg-white p-3 shadow bg-opacity-100 rounded">
                    <h1 class="w-100 bg-secondary rounded mb-3 py-3">Formulaire de réponse</h1>

                    <form method="POST" class="inscription__form needs-validation" novalidate>
                        <div class="row g-3">
                            <!-- Participation -->
                            <div class="col-12 d-flex-center py-3">
                                <span class="fs-5">Je souhaite participer à l'événement :</span>
                                <button
                                    type="button"
                                    class="btn btn-lg mx-3"
                                    v-bind:class="[form_data.is_participating ? 'btn-primary' : 'btn-outline-primary']"
                                    @click="participate(true)"
                                    for="chk_yes"
                                >
                                    Oui
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-lg"
                                    v-bind:class="[
                                        form_data.is_participating ? 'btn-outline-secondary' : 'btn-secondary',
                                    ]"
                                    @click="participate(false)"
                                    for="chk_no"
                                >
                                    Non
                                </button>
                            </div>

                            <!-- Nom -->
                            <div class="col-12 col-sm-6">
                                <div class="form-floating">
                                    <input
                                        class="form-control text-uppercase"
                                        type="text"
                                        v-model="form_data.last_name"
                                        id="last_name"
                                        name="last_name"
                                        placeholder="Dupond"
                                        required
                                    />
                                    <label for="last_name">Nom*</label>
                                    <div class="invalid-feedback">Veuillez renseigner votre nom</div>
                                </div>
                            </div>

                            <!-- Prenom -->
                            <div class="col-12 col-sm-6">
                                <div class="form-floating">
                                    <input
                                        class="form-control text-capitalize"
                                        type="text"
                                        v-model="form_data.first_name"
                                        id="first_name"
                                        name="first_name"
                                        placeholder="Alain"
                                        required
                                    />
                                    <label for="first_name">Prénom*</label>
                                    <div class="invalid-feedback">Veuillez renseigner votre prénom</div>
                                </div>
                            </div>

                            <!-- Email -->
                            <div class="col-12 col-sm-7">
                                <div class="form-floating">
                                    <input
                                        class="form-control"
                                        type="email"
                                        v-model="form_data.email"
                                        id="email_address"
                                        name="email_address"
                                        placeholder="nom@exemple.fr"
                                        required
                                    />
                                    <label for="email_address">Adresse email*</label>
                                    <div class="invalid-feedback">Adresse email invalide</div>
                                </div>
                            </div>

                            <!-- Telephone -->
                            <div class="col-12 col-sm-5">
                                <div class="form-floating">
                                    <input
                                        class="form-control"
                                        type="tel"
                                        v-model="form_data.phone_number"
                                        id="phone_number"
                                        name="phone_number"
                                        placeholder="0701020304"
                                        required
                                    />
                                    <label for="phone_number">Numéro de téléphone*</label>
                                    <div class="invalid-feedback">Veuillez renseigner votre numéro de téléphone</div>
                                </div>
                            </div>

                            <!-- Accompagné -->
                            <div class="col-12 ps-2" v-if="form_data.is_participating && type_invitation > 0">
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        v-model="form_data.is_accompanied"
                                        v-on:change="isAccompaniedChanged"
                                        type="checkbox"
                                        id="is_accompanied"
                                    />
                                    <label class="form-check-label" for="is_accompanied"
                                        >Je souhaite venir accompagné(e)</label
                                    >
                                </div>
                            </div>

                            <!-- Accompagnants -->
                            <div
                                class="col-12 row mx-0 g-2"
                                v-for="(item, i) in form_data.accompagnants"
                                v-bind:key="i"
                            >
                                <!-- Row number -->
                                <div
                                    class="gy-1 col col-sm-auto fs-3 d-flex align-items-start align-items-sm-center text-secondary fw-bold"
                                    v-if="type_invitation == 2"
                                >
                                    <span class="text-primary">#</span>{{ i + 1 }}
                                </div>
                                <!-- Boutton suppression -->
                                <div
                                    class="gy-1 col-auto d-flex-center link-secondary fw-bold order-sm-last"
                                    v-if="type_invitation == 2 && form_data.accompagnants?.length > 1"
                                >
                                    <i
                                        class="cursor-pointer fa-solid fa-trash-alt me-1 fa-lg"
                                        @click="() => removeAccompagnant(i)"
                                    />
                                </div>
                                <!-- Nom accompagnant -->
                                <div class="gy-1 col-12 col-sm" v-bind:key="`c3_${i}`">
                                    <div class="form-floating">
                                        <input
                                            class="form-control text-uppercase"
                                            type="text"
                                            v-model="item.last_name"
                                            v-bind:id="`last_name_${i}`"
                                            v-bind:name="`last_name_${i}`"
                                            placeholder="Dupont"
                                            required
                                        />
                                        <label v-bind:for="`last_name_${i}`">Nom accompagnant(e)*</label>
                                    </div>
                                </div>
                                <!-- Prénom accompagnant -->
                                <div class="gy-1 col-12 col-sm" v-bind:key="`c2_${i}`">
                                    <div class="form-floating">
                                        <input
                                            class="form-control text-capitalize"
                                            type="text"
                                            v-model="item.first_name"
                                            v-bind:id="`first_name_${i}`"
                                            v-bind:name="`first_name_${i}`"
                                            placeholder="Dupont"
                                            required
                                        />
                                        <label v-bind:for="`first_name_${i}`">Prénom accompagnant(e)*</label>
                                    </div>
                                </div>
                                <div class="col-12 order-last d-sm-none" v-if="form_data.accompagnants?.length > i + 1">
                                    <div class="opacity-75 bg-primary mt-2" style="height: 2px"></div>
                                </div>
                            </div>

                            <!-- Boutton ajouter un accompagnant -->
                            <div
                                class="col-12 text-center g-2"
                                v-if="
                                    type_invitation == 2 &&
                                    form_data.is_accompanied &&
                                    form_data.accompagnants?.length < 4
                                "
                            >
                                <button @click="addNewAccompagnant" class="btn btn-outline-secondary" type="button">
                                    <i class="fa-solid fa-plus me-2 fa-sm" />
                                    <span>Ajouter un accompagnant</span>
                                </button>
                            </div>
                        </div>

                        <button @click="register" class="w-100 btn btn-primary btn-lg mt-3" type="button">
                            Valider
                        </button>
                        <div class="mt-2 small text-secondary">* champs obligatoires</div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from "../plugins/router";
import { functions } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";
const DelToso_SaveRegistration = httpsCallable(functions, "DelToso_SaveRegistration");
const DelToso_CheckTypeInvitation = httpsCallable(functions, "DelToso_CheckTypeInvitation");

const empty_form = {
    is_participating: true,
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    is_accompanied: false,
    accompagnants: [],
};

export default {
    props: {
        token: null,
    },
    created() {
        this.cleanData();
    },
    data() {
        return {
            isLoading: false,
            hasError: false,
            form_data: {
                is_participating: true,
                last_name: "Modin",
                first_name: "Damien",
                email: "damien@innov-events.fr",
                phone_number: "0789215568",
                is_accompanied: true,
                accompagnants: [],
            },
            type_invitation: null, // 0|1|2
        };
    },
    methods: {
        participate(is_participating) {
            this.form_data.is_participating = is_participating;
        },
        isAccompaniedChanged() {
            if (this.form_data.is_accompanied) this.addNewAccompagnant();
            else this.form_data.accompagnants = [];
        },
        addNewAccompagnant() {
            this.form_data.accompagnants.push({ first_name: "", last_name: "" });
        },
        removeAccompagnant(i) {
            this.form_data.accompagnants.splice(i, 1);
        },
        async register() {
            if (!this.isLoading) {
                this.isLoading = true;
                const form = document.querySelector(".needs-validation");
                form.classList.add("was-validated");

                this.form_data.token = this.token;
                if (form.checkValidity()) {
                    await DelToso_SaveRegistration(this.form_data)
                        .then((result) => {
                            const data = result.data;
                            if (data) {
                                localStorage.p = this.form_data.is_participating;
                                this.cleanData();
                                router.push({ name: "done" });
                            } else {
                                this.$data.hasError = true;
                                form.classList.remove("was-validated");
                                window.scrollTo(0, 0);
                            }
                        })
                        .catch((error) => {
                            const code = error.code;
                            const message = error.message;
                            const details = error.details;
                            console.log(code, message, details);
                            this.$data.hasError = true;
                            form.classList.remove("was-validated");
                            window.scrollTo(0, 0);
                        });
                }

                this.isLoading = false;
            }
        },
        cleanData() {
            this.form_data = Object.assign({}, empty_form);
            const form = document.querySelector(".needs-validation");
            form?.classList.remove("was-validated");
        },
    },
    asyncComputed: {
        async type_invitation() {
            var _type_invitation = -1;
            _type_invitation = await DelToso_CheckTypeInvitation(this.token)
                .then((result) => {
                    const data = result.data;
                    if (data == -1) {
                        router.push({ name: "home" });
                    } else {
                        return data;
                    }
                })
                .catch((error) => {
                    const code = error.code;
                    const message = error.message;
                    const details = error.details;
                    console.log(code, message, details);
                    router.push({ name: "home" });
                });

            return _type_invitation;
        },
    },
};
</script>

<style lang="scss" scoped>
$primary: #3f4453;
.loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba($color: $primary, $alpha: 0.5);
}
</style>
