/* eslint-disable no-unused-vars */
import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import RegistrationView from "../views/RegistrationView.vue";
import NotFoundView from "../views/NotFoundView.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/inscription",
        name: "inscription",
        component: RegistrationView,
        props: route => ({ token: route.query.t }),
    },
    {
        path: "/done",
        name: "done",
        redirect: (to) => {
            localStorage.fromty = true;
            return { name: "home" };
        },
    },
    {
        path: "/404-not-found",
        name: "404",
        component: NotFoundView,
    },
    {
        path: "*",
        redirect: { name: "home" },
    },
];

const router = new VueRouter({
    mode: "history",
    base: __dirname,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(
                    to.hash && to.hash != "#"
                        ? {
                            selector: to.hash,
                            offset: { x: 0, y: 0 },
                            behavior: "smooth",
                        }
                        : { x: 0, y: 0 }
                );
            }, 250);
        });
    },
});

export default router;
