<template>
    <div class="container-lg my-3 flex-fill body-container" id="accueil">
        <div
            v-if="isFromTY"
            class="alert alert-secondary container-lg d-flex align-items-center rounded-1 text-primary"
            role="alert"
        >
            <i class="fa-solid fa-check me-3"></i>

            <div v-if="isConfirm">Merci pour votre inscription, un email de confirmation vous a été envoyé.</div>
            <div v-else>Merci, votre réponse a été prise en compte.</div>
        </div>

        <img
            src="../assets/img/affiche.svg"
            class="mw-100 shadow-lg border border-5 border-primary-light rounded"
            alt=""
        />
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        let _isFromTY = localStorage.fromty && JSON.parse(localStorage.fromty);
        localStorage.fromty = false;

        let _isConfirm = localStorage.p && JSON.parse(localStorage.p);
        localStorage.p = false;

        return {
            isFromTY: _isFromTY,
            isConfirm: _isConfirm,
        };
    },
};
</script>
